import * as StyledControls from 'modules/ipcts-call-session/components/controls/call-controls.styled';
import AnsweringMachineDispositions from 'modules/ipcts-call-session/components/controls/call-dispositions-answering-machine.component';
import ThreePartyMode from 'modules/ipcts-call-session/components/controls/call-dispositions-three-party-mode.component';
import TwoPartyMode from 'modules/ipcts-call-session/components/controls/call-dispositions-two-party-mode.component';
import Timer from 'modules/ipcts-call-session/components/controls/timer.component';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import {
  ESilentCallDisposition,
  ETerminationReasonCode,
  ECallModes,
} from 'shared/datasources/call-detail-record-service/entities.dto';
import { CallState } from 'shared/datasources/session-state-service/entities.dto';

interface ICallDispositionsMenuProps {
  dispositionCallEnd: () => void;
  addCustomCaption: (text: string, editable?: boolean) => void;
  dispositionSelected: ETerminationReasonCode;
  setDispositionSelected: React.Dispatch<
    React.SetStateAction<ETerminationReasonCode>
  >;
  setSilentDispositionSelected: React.Dispatch<
    React.SetStateAction<ESilentCallDisposition>
  >;
}

const CallDispositionsMenu = ({
  dispositionCallEnd,
  addCustomCaption,
  dispositionSelected,
  setDispositionSelected,
  setSilentDispositionSelected,
}: ICallDispositionsMenuProps) => {
  const [showTimer, setShowTimer] = React.useState(false);
  const [timerSeconds, setTimerSeconds] = React.useState(0);
  const [showSilentOptions, setShowSilentOptions] = React.useState(false); // To be deleted?
  const [normalRadioChecked, setNormalRadioChecked] = React.useState(false); // To be deleted?
  const [silentRadioChecked, setSilentRadioChecked] = React.useState(false); // To be deleted?
  const [nonBillableRadioChecked, setNonBillableRadioChecked] =
    React.useState(false); // To be deleted?
  const [busyRadioChecked, setBusyRadioChecked] = React.useState(false); // To be deleted?
  const [noAnswerRadioChecked, setNoAnswerRadioChecked] = React.useState(false); // To be deleted?

  const [callMode, setCallMode] = React.useState(ECallModes.TwoParty);

  (window as any).changeDisposition = (value: number = 0) => {
    if (value === 2) {
      setCallMode(ECallModes.TwoParty);
      return;
    }
    if (value === 3) {
      setCallMode(ECallModes.ThirdParty);
      return;
    }
    setCallMode(ECallModes.AnsweringMachine);
  };

  // export enum CallState {
  //   TwoOnCall = 'TwoOnCall',
  //   OneOnCallEnd = 'OneOnCallEnd',
  //   NoneOnCall = 'NoneOnCall',
  //   OneOnCall = 'OneOnCall',
  // }

  const setRadioCheckOn = (id: string) => {
    setNormalRadioChecked(
      id === ETerminationReasonCode.Normal ? !normalRadioChecked : false
    );
    setSilentRadioChecked(id === ETerminationReasonCode.Silent);
    setNonBillableRadioChecked(id === ETerminationReasonCode.NonBill);
    setBusyRadioChecked(id === ETerminationReasonCode.Busy);
    setNoAnswerRadioChecked(id === ETerminationReasonCode.NoAnswer);
  };

  const handleDisposition = (selected: string) => {
    const flows = {
      [ETerminationReasonCode.Normal.toString()]: () => {
        setRadioCheckOn(ETerminationReasonCode.Normal);

        setShowSilentOptions(false);
        setDispositionSelected(ETerminationReasonCode.Normal);
      },
      [ETerminationReasonCode.Silent.toString()]: () => {
        setRadioCheckOn(ETerminationReasonCode.Silent);

        setShowSilentOptions(true);
        setDispositionSelected(ETerminationReasonCode.Silent);
      },
      [ETerminationReasonCode.NonBill.toString()]: () => {
        setRadioCheckOn(ETerminationReasonCode.NonBill);

        setShowSilentOptions(false);
        setDispositionSelected(ETerminationReasonCode.NonBill);
        setTimerSeconds(60);
        setShowTimer(true);
      },
      [ETerminationReasonCode.Busy.toString()]: () => {
        setRadioCheckOn(ETerminationReasonCode.Busy);

        setShowSilentOptions(false);
        setDispositionSelected(ETerminationReasonCode.Busy);
        addCustomCaption('(Busy)');
      },
      [ETerminationReasonCode.NoAnswer.toString()]: () => {
        setRadioCheckOn(ETerminationReasonCode.NoAnswer);

        setShowSilentOptions(false);
        setDispositionSelected(ETerminationReasonCode.NoAnswer);
      },
      [ETerminationReasonCode.Illegit.toString()]: () => {},
      [ETerminationReasonCode.OthLang.toString()]: () => {},
      [ETerminationReasonCode.FaxTone.toString()]: () => {},
      [ETerminationReasonCode.SltAnsMn.toString()]: () => {},
      [ETerminationReasonCode.SitTone.toString()]: () => {},
    };
    const result = flows[selected];
    if (!result) {
      return;
    }
    result();
  };

  // const handleSilentOptions = (selected: string) => {
  //   const flows = {
  //     [ESilentCallDispositions.ClearEnding.toString()]: () => {
  //       setDispositionSelected(ETerminationReasonCode.Silent);
  //       setSilentDispositionSelected(ESilentCallDispositions.ClearEnding);
  //       setTimerSeconds(60);
  //       setShowTimer(true);
  //     },
  //     [ESilentCallDispositions.MidCall.toString()]: () => {
  //       setDispositionSelected(ETerminationReasonCode.Silent);
  //       setSilentDispositionSelected(ESilentCallDispositions.MidCall);
  //       setTimerSeconds(300);
  //       setShowTimer(true);
  //     },
  //   };
  //   const result = flows[selected];
  //   if (!result) {
  //     return;
  //   }
  //   result();
  // };

  const showDispositionForCallMode = () => {
    if (callMode === ECallModes.AnsweringMachine) {
      return <AnsweringMachineDispositions />;
    }
    if (callMode === ECallModes.ThirdParty) {
      return (
        <ThreePartyMode
          setTimerSeconds={setTimerSeconds}
          setShowTimer={setShowTimer}
        />
      );
    }
    return (
      <TwoPartyMode
        setTimerSeconds={setTimerSeconds}
        setShowTimer={setShowTimer}
      />
    );
  };

  return (
    <StyledControls.CallControlsBody>
      {showTimer ? (
        <Timer
          seconds={timerSeconds}
          setShowTimer={setShowTimer}
          dispositionCallEnd={dispositionCallEnd}
        />
      ) : (
        showDispositionForCallMode()
      )}
    </StyledControls.CallControlsBody>
  );
};

export default CallDispositionsMenu;
