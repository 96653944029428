import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext, useRef, useState } from 'react';
import { config } from 'shared/config/cts.config';
import { useCorrections } from 'shared/hooks/axon/corrections/use-corrections.hook';
import { incomingAudioSampleRate } from 'shared/hooks/axon/gateway.types';
import { handleAudio } from 'shared/hooks/axon/helpers/handle-audio.helper';
import {
  GatewayEvent,
  type ConnectingToCallEvent,
  type HangUpEvent,
} from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';
import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import { useSocketFactory } from 'shared/utils/socket-factory/use-socket-factory.hook';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import { clearCaptions } from 'state/captions/captionsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import logger from 'services/logger';

const useGateWay = () => {
  const notAcceptingCalls = useRef(false);
  const localCallId = useRef('');
  const { getUserToken } = useContext(AuthenticationContext);
  const user = useSelector((state: RootState) => state.user.value);
  const audio = useSelector((state: RootState) => state.audio);
  const dispatch = useDispatch();
  const { agentTimeBetweenCalls } = useFlags();  
  function getAudio(){
    return audio;
  }
  const {
    callId,
    setCallId,
    setShowLobby,
    setIsCallActive,
    detailedShards,
  } = useContext(LandingPageContext);
  const { updateTranscript } = useUpdateTranscript();
  const callAudioCtx = useRef<AudioContext>(new AudioContext());
  const soundBuffer = new SoundBuffer(
    callAudioCtx.current,
    incomingAudioSampleRate,
    2
  );

  const useGateWaySocket = useSocketFactory({
    wsUrl: `${config.REACT_APP_GW_SOCKET}?agent_id=${user?.employeeID}`,
  });

  const { send, openSocket, closeSocket, setEventsHandlers, isOpen } =
    useGateWaySocket();

  const { sendCorrection, sendDeletion, sendInsertion, sendSubstitution } =
    useCorrections(send);

  const closeConnections = async () => {
    dispatch(clearCaptions())
    closeSocket();
  };
  function sendReadyForCalls() {
    logger.info({
      methodName: 'sendReadyForCalls',
      message: '** GatewayEvent sendReadyForCalls',
    }, false);
    send(
      JSON.stringify({
        event: GatewayEvent.readyForCalls,
      })
    );
}
  function timedReadyForCalls() {
    const waitTime = agentTimeBetweenCalls * 1000;
    setTimeout(() => {
      if (notAcceptingCalls.current) {
        return;
      }
      sendReadyForCalls();
    }, waitTime);
  }
  const sendNotAcceptingCalls = (status: string, reason: string) => {
    notAcceptingCalls.current = true;
    send(
      JSON.stringify({
        event: GatewayEvent.notAcceptingCalls,
        payload: {
          status,
          reason,
        },
      })
    );
  };
  function handleCaptions(msg: any){
    logger.info({
      methodName: '** GatewayEvent.captions',
      callId: localCallId.current,
      message: 'payload: ' + JSON.stringify(msg.payload),
    }, false);
    updateTranscript(msg.payload);
  }
  function handleHello(){
    logger.info({
      methodName: '** GatewayEvent.hello',
    }, false);
    send(
      JSON.stringify({
        event: GatewayEvent.authenticate,
        payload: {
          token: getUserToken(),
          session_login_id: user.sessionLoginId,
        },
      })
    );
  }
  function handleReady(msg: any) {
    logger.info({
      methodName: '** GatewayEvent.ready',
    }, false);
    notAcceptingCalls.current = false;
    sendReadyForCalls();
    setShowLobby(false);
    setIsCallActive(false);
  }
  function handleStartAudio(){
    logger.info({
      methodName: '** GatewayEvent.startAudio',
    }, false);
  }
  function handleConnectionError(msg: any) {
    logger.error({
      methodName: '** GatewayEvent.connectionError',
      callId: localCallId.current,
      message: msg,
    }, false);
  }
  function handleError(msg: any){
    logger.error({
      methodName: '** GatewayEvent.error',
      callId: localCallId.current,
      message: msg,
    }, false);
  }
  function handleCaptionsEnded() {
    logger.info({
      methodName: '** GatewayEvent.captionsEnded',
      callId: localCallId.current,
    }, false);
    closeSocket();
  }
  function handleConnectionClosed(){
    logger.info({
      methodName: '** GatewayEvent.connectionClosed',
      callId: localCallId.current,
    }, false);
    dispatch(clearCaptions())
    setShowLobby(true);
    setIsCallActive(false);
  }
  function handleStatus(msg: any){
    logger.info({
      methodName: '** GatewayEvent.status',
      message: msg,
    }, false);
  }
  function handleAlternativesUnavailable() {
    logger.warn({
      methodName: '** GatewayEvent.alternativesUnavailable',
    }, false);
    detailedShards.current = false;
  }
  function handleReadyForCalls(msg: string) {
    logger.info({
      methodName: '** GatewayEvent.readyForCalls',
      message: msg,
    }, false);
  }
  function connectingToCall(msg: ConnectingToCallEvent) {
    logger.info({
      methodName: '** GatewayEvent.connectingToCall',
      callId: msg.payload?.call_id,
      message: JSON.stringify(msg),
    }, false);
    localCallId.current = msg.payload?.call_id
    setCallId(msg.payload?.call_id);
    setShowLobby(false);
    setIsCallActive(true);
  }
  async function handleHangup(msg: HangUpEvent) {
    logger.info({
      methodName: '** GatewayEvent.hangup',
      callId: localCallId.current,
      message: JSON.stringify(msg),
    }, false);
    setCallId('');
    // 5 second timer goes here
    setShowLobby(false);
    setIsCallActive(false);
    dispatch(clearCaptions());
    logger.info({
      methodName: '** GatewayEvent.hangup',
      callId: localCallId.current,
      message: `isOpen ${isOpen()}`,
    }, false);
    timedReadyForCalls();
  }
  const eventHandlers: Record<string, (value?: any) => any> = {
    [GatewayEvent.captions]: handleCaptions,
    [GatewayEvent.hello]: handleHello,
    [GatewayEvent.ready]: handleReady,
    [GatewayEvent.startAudio]: handleStartAudio,
    [GatewayEvent.connectionError]: handleConnectionError,
    [GatewayEvent.error]: handleError,
    [GatewayEvent.captionsEnded]: handleCaptionsEnded,
    [GatewayEvent.connectionClosed]: handleConnectionClosed,
    [GatewayEvent.status]: handleStatus,
    [GatewayEvent.alternativesUnavailable]: handleAlternativesUnavailable,
    [GatewayEvent.readyForCalls]: handleReadyForCalls,
    [GatewayEvent.connectingToCall]: connectingToCall,
    [GatewayEvent.hangup]: handleHangup,
    [GatewayEvent.audio]: handleAudioMessage,
  };
  function handleAudioMessage(msg: any){
    // logger.info({
    //   methodName: '** GatewayEvent.audio',
    //   message: `audio muted ${getAudio().muted}`,
    // }, false);

    if(!getAudio().muted){
      handleAudio(msg.payload, soundBuffer);
    }
  }

  const openConnection = async () => {
    if (isOpen()) {
      notAcceptingCalls.current = false;
      sendReadyForCalls();
      setShowLobby(false);
      setIsCallActive(false);
    } else {
      setEventsHandlers(eventHandlers);
      openSocket();
    }
  };

  return {
    openConnection,
    closeConnections,
    sendCorrection,
    sendDeletion,
    sendInsertion,
    sendSubstitution,
    sendNotAcceptingCalls,
  };
};

export default useGateWay;
