import qs from 'qs';
import { config } from 'shared/config/cts.config';
import logger from 'services/logger';

export const useLogin = () => {

  const login = (state: string) => {
    const queryParams = qs.stringify({
      response_type: 'code',
      client_id: config.REACT_APP_AUTH_PROVIDER_CLIENT_ID!,
      redirect_uri: window.location.origin,
      scope: config.REACT_APP_AUTH_PROVIDER_SCOPE!,
      state,
    });
    const newURL = `${config.REACT_APP_AUTH_PROVIDER_AUTHORITY}?${queryParams}`;
    try {
      window.location.replace(newURL);
    } catch (error: any) {
      logger.error({
        methodName: 'login',
        parameters: { state },
        message: `Failed with error message ${error.message}`,
        errorStack: error.stack,
      });
    }
  };
  return { login };
};
