import {
  AudioMutedOutlined as AudioMutedOutlinedAntd,
  AudioOutlined as AudioOutlinedAntd,
} from '@ant-design/icons';
import { Icon } from '@iconify/react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

const iconStyle = `
  height: 24px;
  width: 24px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const AudioOutlined = styled(AudioOutlinedAntd)`
  ${iconStyle}
  color:  ${({ theme }) => theme.colors.iconColor} !important;
`;

export const AudioMutedOutlined = styled(AudioMutedOutlinedAntd)`
  ${iconStyle}
  color:  ${({ theme }) => theme.colors.iconColor} !important;
`;

export const SoundOn = styled(Icon)`
  ${iconStyle}
  color:  ${({ theme }) => theme.colors.iconColor} !important;

  margin-left: 21px;
`;
export const SoundOff = styled(Icon)`
  ${iconStyle}
  color:  ${({ theme }) => theme.colors.iconColor} !important;
  margin-right: 21px;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  height: 72px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ theme }) =>
    theme.colors.sessionBackground} !important;
  padding: 8.5px 16px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const HeadphoneIcon = styled(SVG)`
  ${iconStyle}
  path {
    fill: ${({ theme }) => theme.colors.iconColor} !important;
  }
`;
export const HeadphoneIconOff = styled(SVG)`
  ${iconStyle}
  path {
    fill: ${({ theme }) => theme.colors.red} !important;
  }
`;

export const VolumeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 29px;
`;

export const AudioInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const AudioMuteButton = styled.button`
  background-color: ${({ theme }) =>
    theme.colors.muteButtonBackgroundColor} !important;
  border: 1px solid rgba(4, 8, 9, 0.08);
  height: 40px;
  width: 40px;
  display: flex;
  display:none;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
`;
