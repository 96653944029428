import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum UserStates {
  IN_LOBBY = 'InLobby',
  OFFLINE = 'Offline',
  IN_CALL_PAUSE = 'InCallPause',
  IN_CALL = 'InCall',
  IN_QUEUE = 'InQueue',
}

interface PagesState {
  current: UserStates,
}

const initialState: PagesState = {
  current: UserStates.OFFLINE,
}

const userSessionStateSlice = createSlice({
  name: "userSessionState",
  initialState,
  reducers: {
    setUserSessionState: (state, action: PayloadAction<UserStates>) => {
      state.current = action.payload
    }
  }
})

export const { setUserSessionState } = userSessionStateSlice.actions;

export default userSessionStateSlice.reducer;