import { useFlags } from 'launchdarkly-react-client-sdk';
import { config } from 'shared/config/cts.config';
import { useEffect } from 'react';
import logger from 'services/logger';

export const useElectronContextBridge = () => {
  const { setContentProtection, enableDevTools } = useFlags();
  useEffect(() => {
    if (config.REACT_APP_ENVIRONMENT === 'prod') {
      return;
    } else {
      logger.info({
        methodName: 'useElectronContextBridge',
        message: `setContentProtection to: ${setContentProtection}`,
      });
      logger.info({
        methodName: 'useElectronContextBridge',
        message: `set enableDevTools to: ${enableDevTools}`,
      });

      window?.electronAPI?.toggleContentProtection(setContentProtection);
      window?.electronAPI?.toggleEnableDevTools(enableDevTools);
    }
  }, [setContentProtection, enableDevTools]);
};
