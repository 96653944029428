import * as React from 'react';
import IPCTSSessionProvider from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import LandingPageProvider from 'modules/landing-page/context/landing-page.context';

const AgentProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <LandingPageProvider>
      <IPCTSSessionProvider>
        {children}
      </IPCTSSessionProvider>
    </LandingPageProvider>
 );
}

export default AgentProviders;