import BottomControls from 'modules/ipcts-call-session/components/bottom-controls/bottom-controls.component';
import CallControls from 'modules/ipcts-call-session/components/controls/call-controls.component';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import * as React from 'react';
import { Editor, EditorProvider } from 'shared/components/ui/editor';
import {
  ESilentCallDisposition,
  ETerminationReasonCode,
} from 'shared/datasources/call-detail-record-service/entities.dto';
import { CaptionShard, GateWaySockets } from 'shared/hooks/axon/gateway.types';

import * as Styled from './ipcts-session.styled';
import Chronometer from 'components/Chronometer';

interface IIpctsProps {
  text: CaptionShard[];
  isActiveSession: boolean;
  isTokenReady: boolean;
  cardCaptionCallRef: React.MutableRefObject<HTMLDivElement | null> | null;
  handleStartListening: (socket: GateWaySockets) => void;
  setIsCallActive: (b: boolean) => void;
  dispositionCallEnd: () => void;
  handoffCallEnd: () => void;
  addCustomCaption: (text: string, editable?: boolean) => void;
  dispositionSelected: ETerminationReasonCode;
  setDispositionSelected: React.Dispatch<
    React.SetStateAction<ETerminationReasonCode>
  >;
  setSilentDispositionSelected: React.Dispatch<
    React.SetStateAction<ESilentCallDisposition>
  >;
}

export const IpctsSession: React.FunctionComponent<IIpctsProps> = ({
  text,
  isActiveSession,
  isTokenReady,
  cardCaptionCallRef,
  dispositionCallEnd,
  handoffCallEnd,
  addCustomCaption,
  dispositionSelected,
  setDispositionSelected,
  setSilentDispositionSelected,
}) => {
  const { captionAreaFontSize, captionAreaLineHeight, captionAreaFontFamily } =
    React.useContext(IPCTSSessionContext);

  React.useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }, [text]);

  return (
    <Styled.DataContainer>
      <Styled.LeftContainer>
        <Styled.CardSessionContainer>
          <Styled.CardCaptionCall
            ref={cardCaptionCallRef}
            className="vertical-default-scroll"
            isDisabled={isActiveSession && !isTokenReady}
            fontSize={captionAreaFontSize}
            lineHeight={captionAreaLineHeight}
            fontFamily={captionAreaFontFamily}
          >
            <div className="editor-provider-wrapper">
              <EditorProvider>
                <Editor value={text} />
              </EditorProvider>
            </div>
          </Styled.CardCaptionCall>
        </Styled.CardSessionContainer>
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <Styled.ChronoContainer>
          <Chronometer
            id={'callLengthTimer'}
            timeWrapperProps={{ fontWeight: '600', width: '100%' }}
            inQueue
          />
        </Styled.ChronoContainer>
        <CallControls
          dispositionCallEnd={dispositionCallEnd}
          handoffCallEnd={handoffCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
        />
      </Styled.RightContainer>
    </Styled.DataContainer>
  );
};
