import LogoutSVG from 'assets/icons/logout-button-icon.svg';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import * as React from 'react';
import * as Styled from './AppHeader.styled';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

const KEY_FROM_ELECTRON_API = 'logoutAttempt';

const DesktopHeader = () => {
  const { logout } = React.useContext(AuthenticationContext);
  const user = useSelector((state: RootState) => state.user.value);
  const { updateUserState } = useUserState();

  const handleLogout = async () => {
    if (window?.electronAPI && KEY_FROM_ELECTRON_API in window?.electronAPI) {
      await updateUserState(UserStates.OFFLINE);
      window?.electronAPI?.logoutAttempt(false);
    } else {
      await logout();
    }
  };

  return (
    <Styled.DesktopHeaderContainer>
      <Styled.RightContainer>
        <Styled.LogoutButton id="logoutButton" onClick={handleLogout}>
          <Styled.LogoutSVG src={LogoutSVG} />
          Log out
        </Styled.LogoutButton>
        <Styled.UserInfoContainer>
          <Styled.Username id="userText">{user?.agentEmail}</Styled.Username>
        </Styled.UserInfoContainer>
      </Styled.RightContainer>
    </Styled.DesktopHeaderContainer>
  );
};

export default DesktopHeader;
