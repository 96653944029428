import { TimeWrapperProps } from './type';
import styled from 'styled-components';

export const TimeWrapper = styled.div<TimeWrapperProps>`
  color: ${({ color, theme }) => color || theme.colors.chronoColor};
  font-size: ${({ fontSize }) => fontSize || '60px'};
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '48px'};
  width: ${({ width }) => width};
`;
