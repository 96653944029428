import ARROWUP from 'assets/icons/select-arrow-up.svg';
import ARROW from 'assets/icons/select-arrow.svg';
import { isEmpty } from 'lodash';
import * as StyledControls from 'modules/ipcts-call-session/components/controls/call-controls.styled';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAudioDeviceSelector } from 'shared/hooks/use-audio-device-selector.hook';
import logger from 'services/logger';

type ItemType = {
  value: string;
  label: string;
};

enum SoundSelects {
  inputSelect = 'inputSelect',
  outputSelect = 'outputSelect',
}

interface IAudioOptionsProps {
  showLabels?: boolean;
}

export const AudioOptions = ({ showLabels = true }: IAudioOptionsProps) => {
  const { setIsHeadphonesDetected } = useContext(LandingPageContext);
  const outputSelectRef = useRef<Element>();
  const inputSelectRef = useRef<Element>();
  const [isOutputOpen, setIsOutputOpen] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const {
    inputDevicesList,
    outputDevicesList,
    changeInputDevice,
    changeOutputDevice,
  } = useAudioDeviceSelector();
  const [selectOutputDevicesList, setSelectOutputDevicesList] = useState<
    ItemType[]
  >([]);
  const [selectInputDevicesList, setSelectInputDevicesList] = useState<
    ItemType[]
  >([]);
  const [isSpeakerBlockingFlag, setIsSpeakerBlockingFlag] = useState(false);
  const selectEvents = {
    [SoundSelects.inputSelect]: () => {
      setIsInputOpen(!isInputOpen);
      inputSelectRef.current?.dispatchEvent(
        new Event('click', {
          bubbles: true,
          cancelable: false,
        })
      );
    },
    [SoundSelects.outputSelect]: () => {
      setIsOutputOpen(!isOutputOpen);
      outputSelectRef.current?.dispatchEvent(
        new Event('click', {
          bubbles: true,
          cancelable: false,
        })
      );
    },
  };

  const mapDevices = useCallback(() => {
    setSelectOutputDevicesList(
      outputDevicesList.map((device) => ({
        value: device.deviceId,
        label: device.label,
      }))
    );
  }, [outputDevicesList, inputDevicesList]);

  const countNonDefaultAudioOutputDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const usableOutputDevices = mediaDevices.filter(
        (mediaDevices) =>
          mediaDevices.kind === 'audiooutput' &&
          !mediaDevices.label.includes('Default') &&
          !mediaDevices.label.includes('Speakers') &&
          !mediaDevices.label.includes('(Built-in)') &&
          !mediaDevices.label.includes('(Virtual)')
      );
      if (isSpeakerBlockingFlag) {
        if (usableOutputDevices.length > 0) {
          setIsHeadphonesDetected(true);
        } else {
          setIsHeadphonesDetected(false);
        }
      } else {
        setIsHeadphonesDetected(true);
      }
    });
  }, [isSpeakerBlockingFlag]);

  useEffect(() => {
    inputSelectRef.current = document.querySelectorAll('.input-select').item(0);
    outputSelectRef.current = document
      .querySelectorAll('.output-select')
      .item(0);
  }, []);

  useEffect(() => {
    if (isEmpty(outputDevicesList)) {
      return;
    }
    mapDevices();
    countNonDefaultAudioOutputDevices();
  }, [inputDevicesList, outputDevicesList, isSpeakerBlockingFlag]);

  const handleDispositionAction = (select: SoundSelects) => {
    selectEvents[select]();
  };

  const onChangeDevice = (deviceId: any, deviceType: SoundSelects) => {
    if (deviceType === SoundSelects.inputSelect) {
      localStorage.setItem('SelectedInputDevice', deviceId);
      changeInputDevice();
    } else {
      localStorage.setItem('SelectedOutputDevice', deviceId);
      changeOutputDevice(deviceId);
    }
  };

  (window as any).toggleSpeakerBlocking = () => {
    setIsSpeakerBlockingFlag(!isSpeakerBlockingFlag);
    if (isSpeakerBlockingFlag) {
      logger.info({
        methodName: 'toggleSpeakerBlocking',
        message: 'Speaker Audio Blocker feature is disabled',
      }, false);
    } else {
      logger.info({
        methodName: 'toggleSpeakerBlocking',
        message: 'Speaker Audio Blocker feature is enabled',
      }, false);
    }
  };

  return (
    <div>
      <style>
        {`
          .ant-select-dropdown {
            background-color: #080F13;
            border: 1px solid #2E3437;
            top: 48px !important;
          }

          .ant-select-item-option-content {
            color: #E3E6E6 !important;
          }
          .ant-select-item-option-selected{
            background-color: #061E20 !important;
          }

          .ant-select-item-option-active {
            background-color: #061E20 !important;
          }

          .output-select span {
            color: ${
              isOutputOpen ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)'
            } !important;
          }
          .input-select span {
            color: ${
              isInputOpen ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)'
            } !important;
          }
        `}
      </style>
      <StyledControls.SettingsSubtitle className="audio-title">
        Audio Settings
      </StyledControls.SettingsSubtitle>

      {showLabels ? (
        <StyledControls.SettingsText>Speaker</StyledControls.SettingsText>
      ) : (
        <br />
      )}
      <StyledControls.SelectButton
        id="output-dropdown-container"
        isOpen={isOutputOpen}
        optionsquantity={selectOutputDevicesList.length || 1}
        onClick={() => handleDispositionAction(SoundSelects.outputSelect)}
      >
        <StyledControls.SelectDropDown
          id="speakerSelector"
          bordered={false}
          className="output-select"
          defaultValue={
            localStorage.getItem('SelectedOutputDevice') || 'default'
          }
          getPopupContainer={() =>
            document.querySelector('#output-dropdown-container')!
          }
          style={{ width: '100%' }}
          onChange={(value) => onChangeDevice(value, SoundSelects.outputSelect)}
          onClick={(event) => {
            setIsOutputOpen(!isOutputOpen);
            event.stopPropagation();
          }}
          options={selectOutputDevicesList}
          suffixIcon={
            <StyledControls.SVGIcon src={isOutputOpen ? ARROWUP : ARROW} />
          }
        />
      </StyledControls.SelectButton>
    </div>
  );
};
