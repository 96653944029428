import { useLDClient } from 'launchdarkly-react-client-sdk';
import logger from 'services/logger';

export type LDUser = {
  key?: string;
  name: string;
  email: string;
};

export const useLdIdentify = () => {
  const ldClient = useLDClient();

  const setLDUser = async (ldUser: LDUser) => {
    if (!ldClient) return;
    try {
      let result = await ldClient?.identify(ldUser);
      logger.info({
        methodName: 'setLDUser',
        message: ` identify result ${JSON.stringify(result)}`,
      }, false);

      return result;
    } catch (error: any) {
      logger.error({
        methodName: 'setLDUser',
        message: error.message,
        errorStack: error.stack,
      }, false);
    }
  };

  return { setLDUser };
};
