import { useContext, useCallback, useState } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';
import { AffectedShards } from 'shared/hooks/axon/corrections/corrections.types';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCaption,
  setCaptionUserEditing,
} from 'state/captions/captionsSlice';
import {CaptionShard} from 'shared/hooks/axon/gateway.types';
import logger from 'services/logger';


const LINE_BREAK = '\n';
const MAX_WORD_SELECTION = 3;
interface CustomSelection extends Partial<Selection> {
  anchorOffset: number;
  focusOffset: number;
  focusNode: Node;
  removeAllRanges: () => {};
  addRange: (range: any) => {};
}
export const useEditorEvents = () => {
  const [focusedShard, setFocusedShard] = useState<any | null>(null);
  const [multiWordEdit, setMultiWordEdit] = useState<any | null>(null)
  const dispatch = useDispatch()
  const captions = useSelector((state: RootState) => state.captions.value);
  const functionKeys = useSelector((state: RootState) => state.quickwords.functionKeys);
  const quickwords = useSelector((state: RootState) => state.quickwords.quickwords);

  const {
    sendShardCorrection,
  } = useContext(IPCTSSessionContext);

  function syncCaptionShards(affectedShards: AffectedShards[]) {
    for (let shard of affectedShards) {
      dispatch(setCaptionUserEditing({
        shardId: shard.shard_id,
        userEditing: false,
      }))
      logger.info({
        methodName: '** syncCaptionShards',
        message: `captionShard ${shard.shard_id} ${shard.old_text} ${captions[shard.shard_id].userEditing}`,
      }, false);
    }
  }
  function formatMultiWordCorrection() {
    let editor = document.getElementById('rsw-cts-editor');
    let foundPrevious = false;
    for (let shard of multiWordEdit?.affectedShards) {
      let shardParagraph = editor?.querySelector(`p[data-shardid="${shard.shard_id}"]`)
      if (shardParagraph) {
        foundPrevious = true;
        if (shard.old_text != shardParagraph.textContent) {
          let newValue = removeZeroWidthSpace(shardParagraph.textContent as string);
          if (newValue.trim() === '') {
            newValue = '';
          }
          shard.new_text = newValue;
        } else {
          shard.new_text = '';
        }
      } else if (foundPrevious) {
        shard.new_text = '';
      }
    }
    return multiWordEdit.affectedShards;
  }
  function formatAndSendCorrection(affectedShards: AffectedShards[]) {
    logger.info({
      methodName: 'formatAndSendCorrection',
      parameters: { affectedShards },
    }, false);
    sendShardCorrection(affectedShards)
    syncCaptionShards(affectedShards)
    setFocusedShard(null)
    setMultiWordEdit(null);
  }

  const removeZeroWidthSpace = function (text: string) {
    return text.replaceAll(zeroWidthJoiner.space, '');
  }
  function setNodeCaretPosition(elementNode: any, position: number) {
    const selectedRange = document.createRange();
    selectedRange.setStart(elementNode, position);
    selectedRange.collapse(true);
    const selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(selectedRange);
  }

  function setCaretPosition(element: any, childNodeIndex: number, position: number) {
    const selectedRange = document.createRange();
    selectedRange.setStart(element.childNodes[childNodeIndex], position);
    selectedRange.collapse(true);
    const selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(selectedRange);
  }
  function setNewShardFocus(newShard: any) {
    if (newShard === focusedShard) {
      return;
    }
    try {
      const newShardId = newShard?.attributes?.['data-shardid']?.value;
      let focusedShardId = focusedShard?.attributes?.['data-shardid']?.value;
      logger.info({
        methodName: '** setNewShardFocus',
        message: `to ${newShardId} from ${focusedShardId}`,
      }, false);
      setFocusedShard(newShard)
    } catch (error: any) {
      logger.error({
        methodName: '** setNewShardFocus',
        message: `failed for newShard: ${newShard} focusedShard: ${focusedShard} with error ${error.message}`,
        errorStack: error.stack,
      }, false);
    }
  }

  const handleFocus = (event: any) => { }
  const handleBlur = () => {
    setNewShardFocus(null)
  }
  function handleArrowLeft(element: any) {
    let selectionElement = focusedShard?.previousElementSibling;
    if (element === selectionElement
      || ((element === focusedShard?.parentNode
        || element.attributes?.['data-type']?.value === 'shard-word' && element.parentNode !== focusedShard)
        && selectionElement?.attributes?.['data-type']?.value === 'shard')) {
      let lastChildNodeIndex = selectionElement.childNodes.length - 1;
      if (selectionElement.childNodes[lastChildNodeIndex].nodeType !== 3) {
        selectionElement = selectionElement.childNodes[lastChildNodeIndex];
        lastChildNodeIndex = selectionElement.childNodes.length - 1;
      }
      const position = selectionElement.childNodes[lastChildNodeIndex].length;
      setCaretPosition(selectionElement, lastChildNodeIndex, position);
      setNewShardFocus(focusedShard?.previousElementSibling);
    }
  }
  function handleArrowRight(element: any) {
    let selectionElement = focusedShard?.nextElementSibling;
    if ((element === focusedShard?.parentNode
      || element === selectionElement)
      && selectionElement?.attributes?.['data-type']?.value === 'shard') {
      let firstChildNodeIndex = 0;
      if (selectionElement.childNodes[firstChildNodeIndex].nodeType !== 3) {
        selectionElement = selectionElement.childNodes[firstChildNodeIndex];
        firstChildNodeIndex = 0;
      }
      const position = 1;
      setCaretPosition(selectionElement, firstChildNodeIndex, position)
      setNewShardFocus(focusedShard?.nextElementSibling)
    }
  }
  function handleArrowUpDown(element: any) {
    if (element === focusedShard) {
      return;
    }
    const elementDataType = element.attributes?.['data-type']?.value;
    let newFocusedShard = null;
    if (elementDataType === 'shard') {
      newFocusedShard = element;
    } else if (elementDataType === 'shard-word') {
      newFocusedShard = element.parentNode;
    } else if (element === focusedShard?.parentNode) {
      newFocusedShard = element?.nextElementSibling ? element?.nextElementSibling : element?.previousElementSibling;
    }
    setNewShardFocus(newFocusedShard)
  }
  const handleKeyDown = (event: any) => {
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
        break;
      case 'Backspace':
        if (element.attributes?.['data-type']?.value === 'shard'
          || element.parentNode.attributes?.['data-type']?.value === 'shard') {
          const selection: CustomSelection = window.getSelection() as CustomSelection;
          const cursorIndex = selection?.anchorOffset;
          if (cursorIndex === 1 && element.innerText[cursorIndex - 1] === zeroWidthJoiner.space) {
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          let highlightedShards = getHighlightedShards(selection);
          if (highlightedShards?.selectedShardWords?.length) {
            if (highlightedShards.selectedShardWords.length > MAX_WORD_SELECTION) {
              logger.warn({
                methodName: '** handleKeyDown',
                message: `Backspace key reduce selection`,
              }, false);
              reduceSelectionRange(highlightedShards)
              event.preventDefault();
              event.stopPropagation();
            } else if (!multiWordEdit?.affectedShards?.length) {
              setAffectedShards(event, highlightedShards)
            }
          }
        }
        break;
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        return;
        break;
      default:
        logger.info({
          methodName: '** handleKeyDown',
          message: `keycode char ${String.fromCharCode(event.keyCode)}`,
        }, false);
        if (element.attributes?.['data-type']?.value !== 'shard'
        && element.attributes?.['data-type']?.value !== 'shard-word') {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        if ([...event.key].length === 1) {
          if (element.attributes?.['data-type']?.value === 'shard-word'
          && element.className.includes('ant-popover-open')
          ) {
            return;
          }
          let highlightedShards = getSelectionHighlightedShards();
          if (highlightedShards?.selectedShardWords?.length) {
            if (highlightedShards.selectedShardWords.length > MAX_WORD_SELECTION) {
              logger.warn({
                methodName: '** handleKeyDown',
                message: `Enter key reduce selection`,
              }, false);
              reduceSelectionRange(highlightedShards)
              event.preventDefault();
              event.stopPropagation();
            } else if (!multiWordEdit?.affectedShards?.length) {
              setAffectedShards(event, highlightedShards);
            }
          }
        }
        break;
    }
  };
  function setAffectedShards(event: any, highlightedShards: any) {
    let affectedShards: AffectedShards[] = [];
    const lastHighlightedShardWordIndex = highlightedShards.selectedShardWords.length - 1;
    const inputCharacter = event.key === 'Backspace' ? '' : event.key;
    const caretPositionLeftOffset = event.key === 'Backspace' ? 0 : 1;
    let startWordId = highlightedShards.selectedShardWords[0]?.attributes?.['data-wordid']?.value;
    startWordId = parseInt(startWordId, 10);
    let endWordId = highlightedShards.selectedShardWords[lastHighlightedShardWordIndex]?.attributes?.['data-wordid']?.value;
    endWordId = parseInt(endWordId, 10);
    let endShardId = highlightedShards.selectedShardWords[lastHighlightedShardWordIndex]?.attributes?.['data-shardid']?.value;
    endShardId = parseInt(endShardId, 10);
    let foundWord = false;
    let startWord = null
    let foundEndWord = false;
    let firstShardId = 0;
    let tempShards = [];
    for (let index = 0; index < highlightedShards.selectedShards.length; ++index) {
      let shard = highlightedShards.selectedShards[index];
      const shardId = parseInt(shard.attributes['data-shardid'].value);
      if (index === 0) {
        firstShardId = shardId;
      }
      let captionShard = JSON.parse(JSON.stringify(captions[shardId]));
      tempShards.push(captionShard);
      captionShard.userEditing = true;

      for (let shardWordIndex = 0; shardWordIndex < captionShard.shardWords.length; ++shardWordIndex) {
        let shardWord = captionShard.shardWords[shardWordIndex]
        let endText = '';
        if (foundEndWord) {
          break;
        }
        if (shardWord.wordId === startWordId) {
          startWord = shardWord;
          foundWord = true;
          if (shardWord.wordId === endWordId
            && shardId === endShardId) {
            endText = shardWord.wordText.substring(highlightedShards.endWordOffset);
            foundEndWord = true;
          }
          shardWord.wordText = shardWord.wordText.substring(0, highlightedShards.startWordOffset - 1) + inputCharacter + endText;
          continue;
        }
        if (foundWord) {
          if (shardWord.wordId === endWordId
            && shardId === endShardId) {
            endText = shardWord.wordText.substring(highlightedShards.endWordOffset - 1);
            foundEndWord = true;
            let firstShard = tempShards[0];
            // append to last word of first shard
            if (firstShardId === endShardId && startWord) {
              startWord.wordText += endText
            } else {
              let lastWord = firstShard.shardWords.length - 1;
              firstShard.shardWords[lastWord].wordText += endText;
            }
          }
          shardWord.wordText = '';
        }
      }
      const affectedShard: AffectedShards = {
        shard_id: shardId,
        old_text: shard.attributes?.['data-text']?.value,
        new_text: '',
      }
      affectedShards.push(affectedShard)
    }
    for (let tempShard of tempShards) {
      dispatch(setCaption(tempShard))
    }
    setTimeout(() => {
      setNodeCaretPosition(highlightedShards.leftElement, highlightedShards.leftOffset + caretPositionLeftOffset)
    }, 50)
    logger.info({
      methodName: '** setAffectedShards',
      message: JSON.stringify(affectedShards),
    }, false);
    setMultiWordEdit({
      isLeftToRight: highlightedShards.isLeftToRight,
      affectedShards,
    });
    event.preventDefault();
    event.stopPropagation();

  }
  const handleKeyup = (event: any) => {
    logger.native('** handleKeyup event target ', event.target);
    const anchorNode = (window as any)?.getSelection()?.anchorNode;
    let element = anchorNode?.parentNode;
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        if (element.id === "rsw-cts-editor") {
          element = anchorNode.previousElementSibling || anchorNode.nextElementSibling;
        }
        handleArrowUpDown(element)
        break;
      case 'ArrowLeft':
        if (element.id === "rsw-cts-editor") {
          if (!anchorNode.previousElementSibling) {
            return;
          }
          element = anchorNode.previousElementSibling
        }
        handleArrowLeft(element)
        break;
      case 'ArrowRight':
        if (!anchorNode.nextElementSibling) {
          return;
        }
        element = anchorNode.nextElementSibling
        handleArrowRight(element);
        break;
    }
    if(element.attributes?.['data-type']?.value === 'shard'
    || element.attributes?.['data-type']?.value === 'shard-word'){
      let shardWordId: any;
      let shardWordElement;
      if (element.attributes?.['data-type']?.value === 'shard-word') {
        shardWordElement = element;
        shardWordId = parseInt(shardWordElement.attributes?.['data-wordid']?.value, 10);
        element = shardWordElement.parentNode;
      }
      let shardId = element.attributes?.['data-shardid']?.value;
      if (!isNaN(shardId)) {
        shardId = parseInt(shardId, 10)
      }
      const originalValue = element.attributes?.['data-text']?.value;
      if (!originalValue) {
        logger.error({
          methodName: '** handleKeyup',
          message: 'failed to get original shard text for element ' + element.toString(),
        }, false);
        return;
      }

      if((shardWordId !== undefined && !isNaN(shardWordId)
      && event.key.length > 1
      && event.key.charAt(0) === 'F'
      && !isNaN(event.key.charAt(1)))){
        let functionKeySubstitution = functionKeys[event.key]
        if(functionKeySubstitution){

          const cursor: CustomSelection = window.getSelection() as CustomSelection;
          const cursorIndex = cursor?.anchorOffset;
          let insertBefore = false;
          let shardWordLength = shardWordElement.textContent.length;
          let compensateAmount = 0;
          let shard = captions[shardId];
          let shardWord = shard.shardWords.find(word => {
            return word.wordId == shardWordId
          });
          let replacementText = '';

          // are we inserting before punctuation
          let endOfWord = shardWordElement.textContent.substring(cursorIndex).trim();
          if('.,:;'.includes(endOfWord.charAt(0)) && shardWord?.wordText?.length){
            const indexFromEnd = shardWordElement.textContent.length - cursorIndex;
            const insertIndex = shardWord?.wordText?.length - indexFromEnd;
            const shardWordStart = shardWord?.wordText.substring(0, insertIndex)
            const shardWordEnd = shardWord?.wordText.substring(insertIndex)
            replacementText = shardWordStart
                            + ' '
                            + functionKeySubstitution
                            + shardWordEnd;
          } else {
            // are we closer to the beginning or end of the word
            // insert at which ever is closest
            if(shardWordLength === 0){
              insertBefore = true;
            } else if(shardWordElement.textContent.charAt(0) === zeroWidthJoiner.space
                  || shardWordElement.textContent.charAt(0) === ' '){
              ++compensateAmount;

              if(shardWordLength > 0
              && shardWordElement.textContent.charAt(1) === ' '){
                ++compensateAmount;
              }
              let wordMiddle = (shardWordLength-compensateAmount)/2;
              if((cursorIndex - compensateAmount) <= wordMiddle){
                // insert at beginning
                insertBefore = true;
              }
            }

            replacementText = insertBefore
              ? ' ' + functionKeySubstitution + ' ' + shardWord?.wordText
              : ' ' + shardWord?.wordText + ' ' + functionKeySubstitution;
          }
          const affectedShards: AffectedShards[] = [];
          let newShardText = replaceWordInShardText(shard, shardWordId, replacementText)
          affectedShards.push({
            shard_id: shardId,
            old_text: originalValue,
            new_text: newShardText,
          });
          formatAndSendCorrection(affectedShards);
        }
      }
  
      if (event.key === 'Enter') {

        if (event.ctrlKey) {
          const cursor: CustomSelection = window.getSelection() as CustomSelection;
          const cursorIndex = cursor?.anchorOffset;
          const text = element.innerText;
          const shardStart = text.substring(0, cursorIndex)
          const shardEnd = text.substring(cursorIndex)
          const newText = shardStart + LINE_BREAK + shardEnd;
          const affectedShards: AffectedShards[] = [{
            shard_id: shardId,
            old_text: originalValue,
            new_text: removeZeroWidthSpace(newText),
          }];
          formatAndSendCorrection(affectedShards);
          logger.info({
            methodName: '** handleKeyup',
            message: 'send linebreak ShardCorrection ' + element.toString(),
          }, false);
          return;
        }
        if (originalValue === element.textContent) {
          // nothing to do
          return;
        }
        logger.info({
          methodName: '** handleKeyup',
          message: `sendShardCorrection ${shardId} ${element.textContent}`,
        }, false);
        const shardWords = removeZeroWidthSpace(element.textContent).trim().split(' ');
        let foundQuickWord = false;
        for(let shardWordIndex = 0; shardWordIndex < shardWords.length; ++shardWordIndex){
          // trim any beginnnig or ending punctuation.
          let shardWord = shardWords[shardWordIndex].replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,' ').trim();
          if(quickwords[shardWord]){
            shardWords[shardWordIndex] = quickwords[shardWord.trim()];
            foundQuickWord = true;
          }
        }
        if(foundQuickWord){
          const affectedShards: AffectedShards[] = [];
          affectedShards.push({
            shard_id: shardId,
            old_text: originalValue,
            new_text: shardWords.join(' '),
          });
          formatAndSendCorrection(affectedShards);
  
        } else if (multiWordEdit?.affectedShards?.length) {
          formatMultiWordCorrection();
          formatAndSendCorrection(multiWordEdit.affectedShards)
        } else {
          const affectedShards: AffectedShards[] = [];
          affectedShards.push({
            shard_id: shardId,
            old_text: originalValue,
            new_text: removeZeroWidthSpace(element.textContent),
          });
          formatAndSendCorrection(affectedShards)
        }
      }
    }
  };
  const handleClick = (event: any) => {
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    logger.info({
      methodName: '** handleClick',
      message: `element ` + element.toString(),
    }, false);

    if (element.attributes?.['data-type']?.value === 'shard') {
      setNewShardFocus(element)
    } else if (element.parentNode.attributes?.['data-type']?.value === 'shard') {
      setNewShardFocus(element.parentNode)
    } else {
      setNewShardFocus(null)
    }
  };

  function getSelectedShardWords(shard: any, startWord?: any, endWord?: any) {
    let selectedWords = shard.querySelectorAll('span[data-type="shard-word"]');
    let startFound = !startWord;
    let selectedShardWords = [];
    for (let word of selectedWords) {
      if (!startFound) {
        startFound = word === startWord
      }
      if (startFound) {
        selectedShardWords.push(word)
      }
      if (word === endWord) {
        break;
      }
    }
    return selectedShardWords;
  }
  function reduceSelectionRange(highlightedShards: any) {
    const selectedShardWordsLength = highlightedShards.selectedShardWords.length;
    if (selectedShardWordsLength <= MAX_WORD_SELECTION) {
      return;
    }
    const range = document.createRange();
    if (highlightedShards.isLeftToRight) {
      let newEnd = highlightedShards.selectedShardWords[MAX_WORD_SELECTION - 1];
      range.setStart(highlightedShards.leftElement, highlightedShards.leftOffset);
      range.setEnd(newEnd, newEnd.childNodes.length);
    } else {
      let newStart = highlightedShards.selectedShardWords[selectedShardWordsLength - MAX_WORD_SELECTION];
      if (newStart.childNodes) {
        // position one to start at beginning of the first word
        // shard words start with spaces
        range.setStart(newStart.childNodes[0], 1);
      } else {
        range.setStart(newStart, 0);
      }
      range.setEnd(highlightedShards.rightElement, highlightedShards.rightOffset);
    }
    const selection: CustomSelection = window.getSelection() as CustomSelection;
    selection!.removeAllRanges();
    selection!.addRange(range);
  }
  function getSelectionHighlightedShards() {
    const selection: CustomSelection = window.getSelection() as CustomSelection;
    return getHighlightedShards(selection)
  }

  function highlighIsLeftToRight(selection: CustomSelection) {
    let isLeftToRight = false;
    if (selection?.type === 'Range') {
      let position = selection?.anchorNode?.compareDocumentPosition(selection.focusNode)
      if (!position && selection.anchorOffset > selection?.focusOffset
        || position === Node.DOCUMENT_POSITION_PRECEDING) {
        isLeftToRight = false;
      } else {
        isLeftToRight = true;
      }
    }
    return isLeftToRight;
  }
  function findShardAndWordParents(element: any, focusOffset: number, isLeftToRight = true, isEnd = false) {
    let searchElement = element;
    let foundWord;
    let foundShard;
    try {
      while (searchElement?.attributes?.['data-type']?.value !== 'shard') {
        if (searchElement?.attributes?.['data-type']?.value === 'shard-word') {
          foundWord = searchElement
          foundShard = searchElement.parentElement;
          break;
        }
        if (isLeftToRight && isEnd) {
          if (searchElement.parentNode.id === searchElement.id) {
            searchElement = searchElement.previousElementSibling;
            break;
          }
        }
        if (searchElement.id === 'rsw-cts-editor') {
          break;
        }
        searchElement = searchElement.parentElement;
      }
      if (!foundShard && searchElement?.attributes?.['data-type']?.value !== 'shard') {
        logger.error({
          methodName: '** findShardAndWordParents',
          message: `unable to find shardWord for element ` + element.toString(),
        }, false);
        foundShard = null;
      }
    } catch (error: any) {
      logger.error({
        methodName: '** findShardAndWordParents',
        message: `failed searching from element ${element.toString()} with error ${error.message}`,
        errorStack: error.stack,
      }, false);
    }
    return {
      foundWord,
      foundShard,
    }
  }
  function getHighlightedShards(selection: any) {
    if(selection.type !== 'Range'){
      return {}
    }
    let isLeftToRight = highlighIsLeftToRight(selection)
    let leftElement = isLeftToRight ? selection?.anchorNode : selection?.focusNode;
    let rightElement = isLeftToRight ? selection?.focusNode : selection?.anchorNode;
    let leftOffset = isLeftToRight ? selection?.anchorOffset : selection?.focusOffset;
    let rightOffset = isLeftToRight ? selection?.focusOffset : selection?.anchorOffset;
    let startShard = null;
    let endShard = null;
    let startWord = null;
    let endWord = null;
    let selectedShards = [];
    let selectedShardWords: any[] = [];
    let startWordOffset = 0;
    let endWordOffset = 0;

    try {
      let foundParents = findShardAndWordParents(leftElement, leftOffset, isLeftToRight);
      startShard = foundParents?.foundShard;
      startWord = foundParents?.foundWord;

      let foundEndParents = findShardAndWordParents(rightElement, rightOffset, isLeftToRight, true);
      endShard = foundEndParents.foundShard
      endWord = foundEndParents.foundWord
      if (isLeftToRight && rightOffset === 1) {
        // don't select the word if only the zero width space char was the focus
        if (endWord.previousElementSibling) {
          endWord = endWord.previousElementSibling
        } else if (endShard.previousElementSibling) {
          endShard = endShard.previousElementSibling;
          endWord = endShard.lastElementChild;
        }
      }
      // Start Word Offset
      if (startWord.childNodes.length < 2) {
        startWordOffset = leftOffset;
      } else {
        for (let childNode of startWord.childNodes) {
          if (childNode === leftElement) {
            startWordOffset += leftOffset;
            break;
          }
          startWordOffset += childNode.innerText.length;
        }
      }
      if (endWord.childNodes.length < 2) {
        endWordOffset = rightOffset;
      } else {
        for (let childNode of endWord.childNodes) {
          if (childNode === leftElement) {
            endWordOffset += leftOffset;
            break;
          }
          endWordOffset += childNode.innerText.length;
        }
      }
      if (startShard && endShard) {
        selectedShards.push(startShard)
        selectedShardWords = getSelectedShardWords(startShard, startWord, endWord)
      }
      if (startShard !== endShard) {
        let searchShard = startShard.nextElementSibling;
        while (searchShard
          && searchShard !== endShard) {
          selectedShards.push(searchShard)
          selectedShardWords = [...selectedShardWords, ...getSelectedShardWords(searchShard)];
          searchShard = searchShard.nextElementSibling
        }
        selectedShards.push(endShard)
        selectedShardWords = [...selectedShardWords, ...getSelectedShardWords(endShard, null, endWord)]
      }
    } catch (error: any) {
      logger.error({
        methodName: '** getHighlightedShards',
        message: error.message,
        errorStack: error.stack,
      }, false);
    }

    return {
      selection,
      selectedShards,
      selectedShardWords,
      isLeftToRight,
      leftElement,
      leftOffset,
      rightElement,
      rightOffset,
      startWordOffset,
      endWordOffset,
    }
  }

  const handleMouseUp = (event: any) => {
    const selection: CustomSelection = window.getSelection() as CustomSelection;
    if (selection?.type === 'Range') {
      const shardSelection = getHighlightedShards(selection)
      if(shardSelection?.selection){
        logger.native('** handleMouseUp ',shardSelection);
        reduceSelectionRange(shardSelection);
      }
    }
  }
  function replaceWordInShardText(shard: CaptionShard, wordId: number, newText: string){
    // build shard text from each word
    // substitute word text for matching word id with new text
    let newShardText = ''
    for (let shardWord of shard.shardWords) {
      if (shardWord.wordId === wordId) {
        newShardText += ' ' + newText
      } else {
        newShardText += ' ' + shardWord.wordText
      }
    }
    return newShardText;
  }
  const handleCorrectionMenuClick = (shardId: number, wordId: number, replaceText: string, newText: string) => {
    logger.info({
      methodName: '** handleCorrectionMenuClick',
      parameters: {
        shardId,
        wordId,
        replaceText,
        newText
      },
    }, false);
    // get the captionShard
    let shard = captions[shardId]
    // build shard text from each word
    // substitute word text for matching word id with new text
    let newShardText = replaceWordInShardText(shard, wordId, newText)
    let originalValue = shard.formattedText;
    const affectedShards: AffectedShards[] = [];
    affectedShards.push({
      shard_id: shardId,
      old_text: originalValue,
      new_text: newShardText,
    });
    formatAndSendCorrection(affectedShards);
  }

  function handleShardWordContextMenu(event: any) {
    const element = event.target;
    logger.info({
      methodName: '** handleShardWordContextMenu',
      message: element.toString(),
    }, false);
    if (element.attributes?.['data-type']?.value === 'shard-word') {
      const selectedRange = document.createRange();
      // position one to start at beginning of the first word
      // shard words start with spaces
      selectedRange.setStart(element.childNodes[0], 1);
      selectedRange.setEnd(element.lastChild, element.lastChild.length);
      const selection = window.getSelection();
      selection!.removeAllRanges();
      selection!.addRange(selectedRange);
    }
  }

  return {
    handleFocus,
    handleBlur,
    handleKeyDown,
    handleKeyup,
    handleClick,
    handleCorrectionMenuClick,
    handleMouseUp,
    handleShardWordContextMenu,
  };

}