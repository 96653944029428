import { config } from 'shared/config/cts.config';
import { QuickWord, QuickWordObject, setFunctionKeys, setQuickwords } from 'state/quickwords/quickwordsSlice';
import { useDispatch } from 'react-redux';
import logger from 'services/logger';

export const useQuickWords = () => {
  const dispatch = useDispatch()

  const FUNCTION_KEY_REGEX = /F\d+/;
  const quickWordConverter = (quickwords: QuickWord[]) =>
    quickwords.reduce((acc: QuickWordObject, quickword: QuickWord) => {
      const { abbreviation, description } = quickword;
      acc[abbreviation] = description;
      return acc;
    }, {});

  async function fetchQuickwords(accessToken: string){
    let userStorage;
    if (localStorage.getItem('user')) {
      userStorage = JSON.parse(localStorage.getItem('user')!);
    }

    let quickwordData;
    try {
      const headers: HeadersInit = new Headers();
      headers.set('Authorization', `Bearer ${accessToken}`);
      const data = await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/quickwords`,
        {
          method: 'GET',
          headers,
          mode: 'cors',
        }
      );
      quickwordData = await data.json();

      let quickwords = quickWordConverter(
        quickwordData?.data?.filter(
          (quickword: QuickWord) =>
            !quickword.abbreviation.match(FUNCTION_KEY_REGEX)
        )
      )
      let functionKeys = quickWordConverter(
        quickwordData?.data
          ?.filter((quickword: QuickWord) =>
            quickword?.abbreviation.match(FUNCTION_KEY_REGEX)
          )
          .map((quickword: QuickWord) => ({
            ...quickword,
            abbreviation: quickword.abbreviation.split('_')[0],
          }))
      );
      dispatch(setQuickwords(quickwords));
      dispatch(setFunctionKeys(functionKeys));

    } catch (error: any) {
      logger.error({
        methodName: 'useQuickWords',
        message: `Failed with error message ${error.message}`,
        errorStack: error.stack,
      });
    }
    return quickwordData;
  };
  async function loadQuickwords(accessToken: string){
    let result = await fetchQuickwords(accessToken);
    if(!result){
      // incase we get 502 try one more time
      await fetchQuickwords(accessToken);
    }
  }
  return {
    loadQuickwords,
  };  
}