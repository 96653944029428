import { useContext, useEffect } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { QueueScreen } from 'shared/components/ui/queue-screen/queue-screen.component';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSendDataScienceEventMessage, BillingSessionStates } from 'shared/hooks/data-science-api/use-send-data-science-event-message.hook';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { useOAuth } from 'modules/authentication/oauth.context';

const AgentQueue = () => {
  const {
    setShowQueue,
    handleQueueCancel,
  } = useContext(LandingPageContext);

  const {
    sendNotAcceptingCalls,
  } = useContext(IPCTSSessionContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { sendSessionUpdateEventMessage } = useSendDataScienceEventMessage();
  const { accessToken } = useOAuth();
  const user = useSelector((state: RootState) => state.user.value);

  const handleCancelQueue = () => {
    sendSessionUpdateEventMessage(
      accessToken,
      user.sessionLoginId,
      user.employeeID,
      BillingSessionStates.LeaveQueue
    )
    sendNotAcceptingCalls('break', 'Out to lunch')
    navigate('/agent-lobby');
    setShowQueue(false);
    handleQueueCancel();
  }

  useEffect(() => {
    let from = location.state?.from?.pathname;
    const state = from.includes('agent-lobby') ? BillingSessionStates.JoinQueue : BillingSessionStates.WaitingForNextCall;
    sendSessionUpdateEventMessage(
      accessToken,
      user.sessionLoginId,
      user.employeeID,
      state
    )
  }, [])

  return (
    <QueueScreen onCancelQueue={handleCancelQueue} />
  )
}

export default AgentQueue;