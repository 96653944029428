import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import logger from 'services/logger';

export const handleAudio = async (
  binaryData: Blob,
  soundBuffer: SoundBuffer
) => {
  try {
    const arrayBuffer = await binaryData.arrayBuffer();

    if (arrayBuffer.byteLength % 2 !== 0) {
      return;
    }

    const array16 = new Int16Array(arrayBuffer);
    soundBuffer.addChunk(array16);
  } catch (error: any) {
    logger.error({
      methodName: '** handleAudio',
      message: error.message,
      errorStack: error.stack,
    }, false);
  }
};
