import { Duration } from 'luxon';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import * as Styled from './Chronometer.styled';
import { TimeWrapperProps } from './type';

interface IChronometer {
  id: string;
  timeWrapperProps?: TimeWrapperProps;
  inQueue: boolean;
}

const Chronometer = ({ id, timeWrapperProps, inQueue }: IChronometer) => {
  const [time, setTime] = React.useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [counter, setCounter] = React.useState(0);
  const [initialTime] = useState<Moment>(moment(new Date()));

  const format = (number: number) =>
    String(number).length === 1 ? `0${number}` : `${number}`;

  useEffect(() => {
    let intervalId: ReturnType<typeof setTimeout>;
    const tempDate = moment(new Date());

    if (inQueue) {
      intervalId = setInterval(() => {
        const {
          hours = 0,
          minutes = 0,
          seconds = 0,
        } = Duration.fromMillis(tempDate.diff(initialTime))
          .shiftTo('hours', 'minutes', 'seconds')
          .toObject();

        setTime({
          hours,
          minutes,
          seconds: Math.floor(seconds),
        });

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [inQueue, counter]);

  return (
    <Styled.TimeWrapper id={id} {...timeWrapperProps}>
      {format(time.hours)}:{format(time.minutes)}:{format(time.seconds)}
    </Styled.TimeWrapper>
  );
};

export default Chronometer;
