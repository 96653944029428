import { useRef } from 'react';
import { GatewayEvent } from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import logger from 'services/logger';

export interface ISocketFactory {
  wsUrl: string;
}

type GwMessage = {
  event: string;
  payload: any;
};

export const useSocketFactory = ({
  wsUrl,
}: ISocketFactory) => {
  const audio = useSelector((state: RootState) => state.audio);

  const socket = useRef<WebSocket | null>(null);
  const eventHandlers = useRef<Record<string, (value?: any) => any> | null>(
    null
  );
  const setEventsHandlers = (hadlers: Record<string, (value?: any) => any>) => {
    eventHandlers.current = hadlers;
  };
  const handleMessage = (msg: GwMessage) => {
    if (!eventHandlers.current) {
      logger.error({
        methodName: '** SocketFactory handleMessage',
        message: 'Error: No Events Loaded in Socket',
      }, false);
      return;
    }
    const handler = eventHandlers.current[msg.event];
    if (!handler) {
      logger.error({
        methodName: '** SocketFactory handleMessage',
        message: `Error: Handler not found for this event: ${msg.event}`,
      }, false);
      return;
    }
    handler(msg);
  };

  const waitForOpenConnection = () => {
    return new Promise((resolve, reject) => {
      const maxNumberOfAttempts = 10
      const intervalTime = 100
      let currentAttempt = 0
      if(!socket.current){
        resolve(false);
        return;
      }
      if (isOpen()){
        resolve(true);
        return;
      }
      const interval = setInterval(() => {
        if (isOpen()) {
          clearInterval(interval)
          logger.info({
            methodName: '** waitForOpenConnection',
            message: `open on attempt ${currentAttempt}`
          }, false);
          resolve(true)
        } else {
          if (currentAttempt > maxNumberOfAttempts - 1) {
            reject(new Error('waitForOpenConnection Maximum number of attempts exceeded'))
          } else if (socket?.current?.readyState === WebSocket.CLOSING) {
            reject(new Error('waitForOpenConnection socket closing'))
          }
          clearInterval(interval)
        }
        currentAttempt++
        logger.info({
          methodName: '** waitForOpenConnection',
          message: `attempt ${currentAttempt}`
        }, false);
      }, intervalTime)
    })
  }
  const send = async (message: string | ArrayBuffer) => {
    if (socket.current) {
      await waitForOpenConnection();
      logger.info({
        methodName: '** SocketFactory send',
        message: message.toString(),
      }, false);
      socket.current.send(message)
    }
  };

  const openSocket = async () => {
    const url = new URL(wsUrl);
    await waitForOpenConnection()
    if(isOpen()){
      return;
    }

    socket.current = new WebSocket(url);
    socket.current.onerror = (errorEvent) => {
      logger.error({
        methodName: '** SocketFactory onerror',
        message: `url: ${url.toString()} error event: ${errorEvent.toString()}`,
      });
      handleMessage({event: GatewayEvent.connectionError, payload: errorEvent });
    };
    socket.current.onopen = () => {
      logger.info({
        methodName: '** SocketFactory onopen',
        message: url.toString(),
      }, false);
    };
    socket.current.onclose = (event) => {
      logger.info({
        methodName: '** SocketFactory onclose',
        message: event.toString(),
      }, false);
      handleMessage({ event: GatewayEvent.connectionClosed, payload: event });
    };
    socket.current.onmessage = (e: MessageEvent) => {
      if (e.data instanceof Blob) {
        handleMessage({ event: GatewayEvent.audio, payload: e.data });
      } else {
        const msg = JSON.parse(e.data);
        handleMessage(msg);
      }
    };

  }

  const closeSocket = () => {
    if (!socket.current) return;
    socket.current!.close();
    socket.current = null;
  };

  const isOpen = () => {
    return socket?.current?.readyState === WebSocket.OPEN;
  }

  return () => ({
    send,
    openSocket,
    closeSocket,
    setEventsHandlers,
    isOpen,
  });
};