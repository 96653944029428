import { config } from 'shared/config/cts.config';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import { setUserSessionState, UserStates } from 'state/userSessionState/userSessionStateSlice';
import logger from 'services/logger';

type UserState = {
  stateName: UserStates;
  sessionId: string;
};

export const useUserState = () => {
  const userSessionState = useSelector((state: RootState) => state.userSessionState.current);
  const dispatch = useDispatch();

  async function postUserState(userToken: string, userState: UserState) {
    let response;
    try {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set('Authorization', `Bearer ${userToken}`);
  
      response = await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/user-state`,
        {
          method: 'POST',
          headers: requestHeaders,
          mode: 'cors',
          keepalive: true,
          body: JSON.stringify(userState),
        }
      );
      dispatch(setUserSessionState(userState.stateName));
    } catch (error: any) {
      logger.error({
        methodName: 'postUserState',
        parameters: { userState },
        message: `Failed with error message ${error.message} status code ${response?.status}`,
        errorStack: error.stack,
      });
    }
    return response;
  }

  const updateUserState = async (stateName: UserStates) => {
    let tokenInfoStorage: any;
    let userStorage: any;
    if (localStorage.getItem('tokenInfo')) {
      tokenInfoStorage = JSON.parse(localStorage.getItem('tokenInfo')!);
    }
    if (localStorage.getItem('user')) {
      userStorage = JSON.parse(localStorage.getItem('user')!);
    }  

    if(stateName !== userSessionState){
      let response = await postUserState(tokenInfoStorage?.accessToken as string, {
        stateName,
        sessionId: userStorage?.sessionLoginId as string,
      });
      if(!response){
        // incase we get 502 try one more time
        logger.warn({
          methodName: 'updateUserState',
          message: `retry postUserState`,
        }, false);
        await postUserState(tokenInfoStorage?.accessToken as string, {
          stateName,
          sessionId: userStorage?.sessionLoginId as string,
        });
      }
    }
  }
  return {
    updateUserState,
  };
};

