import { config } from 'shared/config/cts.config';
import logger from 'services/logger';

export const useAppUser = () => {
  async function fetchAppUser(userToken: string, activeDirectoryId: string) {
    let response;
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);
    let user;
    try {
      response = await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/user?${new URLSearchParams({
          activeDirectoryId,
        }).toString()}`,
        {
          method: 'GET',
          headers: requestHeaders,
          mode: 'cors',
        }
      );
      if (response?.status === 200) {
        user = await response?.json();
      } else {
        throw `Failed to fetch with status code ${response?.status}`
      }
      return user;
    } catch (error: any) {
      logger.error({
        methodName: 'getAppUser',
        parameters: { activeDirectoryId },
        message: `Failed with error message ${error.message}`,
        errorStack: error.stack,
      });
      return error;
    }
  }
  async function getAppUser(userToken: string, activeDirectoryId: string) {
    let user = await fetchAppUser(userToken, activeDirectoryId);
    if(!user?.agentEmail){
      // incase we get 502 try one more time
      let result = await fetchAppUser(userToken, activeDirectoryId)
      if(result?.agentEmail){
        user = result;
      } else {
        throw result;
      }
    }
    return user;
  }

  return {
    getAppUser,
  };
};
