import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import {
  AuthenticationContext,
} from 'modules/authentication/authentication.context';
import * as React from 'react';
import {
  ESilentCallDisposition,
  ETerminationReasonCode,
} from 'shared/datasources/call-detail-record-service/entities.dto';
import { useNotificationsService } from 'shared/hooks/use-notifications-service.hook';
import { LandingPageContextData } from './landing-page.types';
import { useNavigate } from 'react-router-dom';

export const LandingPageContext = React.createContext<LandingPageContextData>({
  dispositionSelected: ETerminationReasonCode.Unknown,
  setDispositionSelected: () => {},
  silentDispositionSelected: ESilentCallDisposition.Unknown,
  setSilentDispositionSelected: () => {},
  setAsrProvider: () => {},
  isCallActive: false,
  setIsCallActive: () => {},
  callId: '',
  agentId: '',
  requestedHandoff: '',
  setRequestedHandoff: () => {},
  asrProvider: '',
  showQueue: false,
  setShowQueue: () => {},
  handleQueueCancel: () => {},
  is911Call: false,
  setIs911Call: () => {},
  isHeadphonesDetected: false,
  setIsHeadphonesDetected: () => {},
  setCallId: () => {},
  jssipAudioRef: undefined,
  detailedShards: { current: true },
  showLobby: false,
  setShowLobby: () => {},
});

const LandingPageProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = React.useContext(AuthenticationContext);
  const { useAgentQueue } = useFlags();
  const usrId = React.useRef<number>();
  const sessionId = React.useRef<number>();
  const [dispositionSelected, setDispositionSelected] =
    React.useState<ETerminationReasonCode>(ETerminationReasonCode.Unknown);
  const [silentDispositionSelected, setSilentDispositionSelected] =
    React.useState<ESilentCallDisposition>(ESilentCallDisposition.Unknown);
  const [asrProvider, setAsrProvider] = React.useState('');
  const [sessionStart, setSessionStart] = React.useState('');
  const [sessionEnd, setSessionEnd] = React.useState('');
  const [requestedHandoff, setRequestedHandoff] = React.useState('');
  const [showQueue, setShowQueue] = React.useState(false);
  const [showLobby, setShowLobby] = React.useState(true);
  const navigate = useNavigate();

  const [is911Call, setIs911Call] = React.useState<boolean>(false);
  const [isHeadphonesDetected, setIsHeadphonesDetected] =
    React.useState<boolean>(true);
  const jssipAudioRef =
    React.useRef() as React.MutableRefObject<HTMLAudioElement>;
  const detailedShards = React.useRef<boolean>(true);

  (window as any).is911 = () => {
    if (is911Call) {
      setIs911Call(false);
    } else {
      setIs911Call(true);
    }
  };
  const {
    isCallActive,
    setIsCallActive: setIsCallActiveNotificationService,
    callId,
    setCallId,
  } = useNotificationsService();

  const handleCallStarted = async () => {};

  const setIsCallActive = (isActive: boolean) => {
    if(isActive != isCallActive){
      if(isActive){
        // navigate to session page
        navigate('/agent-session');
      }
    }
    setIsCallActiveNotificationService(isActive)
  };

  const handleQueueCancel = async () => {
    setShowLobby(true)
    setIsCallActive(false)
  };

  React.useEffect(() => {
    if (isCallActive) {
      setSessionStart(`${DateTime.now().toISO()}`);
      if (useAgentQueue) {
        handleCallStarted();
      }
    }
    if (!isCallActive && sessionStart !== '') {
      setSessionEnd(`${DateTime.now().toISO()}`);
    }
  }, [isCallActive]);

  return (
    <LandingPageContext.Provider
      value={{
        isCallActive,
        setIsCallActive,
        callId,
        dispositionSelected,
        setDispositionSelected,
        silentDispositionSelected,
        setSilentDispositionSelected,
        setAsrProvider,
        asrProvider,
        agentId: usrId.current?.toString()!,
        requestedHandoff,
        setRequestedHandoff,
        showQueue,
        setShowQueue,
        handleQueueCancel,
        is911Call,
        setIs911Call,
        isHeadphonesDetected,
        setIsHeadphonesDetected,
        setCallId,
        jssipAudioRef: jssipAudioRef.current,
        detailedShards,
        showLobby,
        setShowLobby,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};

export default LandingPageProvider;