import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { openErrorNotification } from 'shared/components/ui/alerts/notification.component';
import { NotificationService } from 'shared/datasources/agent-service/entities.dto';
import logger from 'services/logger';

const KEEP_ALIVE = 2000;
const TIMEOUT = 4000;

// This endpoint is not being used currently as it is part of Modern Relay.
// Will be kept for reference purposes.
export const useNotificationsService = () => {
  const agentConnection = useRef();

  const [currentNotificationId, setCurrentNotificationId] = useState<string>();
  const [agentInfo, setAgentInfo] = useState<NotificationService>();
  const [isCallActive, setIsCallActive] = useState(false);
  const [callId, setCallId] = useState('');
  const { setShowQueue } = useContext(LandingPageContext);

  const handleMessage = useCallback(
    (_: any, connectionID: string) => {
      logger.native(`CONNECTED TO NOTIFICATION SERVICE ID: ${connectionID}`);

      setCurrentNotificationId(connectionID);

      if (agentInfo) {
        const updatedAgent: NotificationService = {
          ...agentInfo,
          NotificationId: connectionID,
        };
      }
    },
    [setCurrentNotificationId, agentInfo]
  );

  const handleIncomingCall = useCallback(
    async (holdServerId: string, callId: string) => {
      logger.native('INCOMING CALL EVENT RECEIVED', `callId:${callId}}`);
    },
    []
  );

  const handleAgentIsReady = (agentConnId: string, callId: string) => {
    logger.native('EVENT AGENTISREADY RECEIVED');
    logger.native('agentConnId', agentConnId);
    logger.native('callId', callId);
  };

  const startConnection = useCallback(async () => {
  }, []);

  useEffect(() => {
  }, [handleMessage, handleIncomingCall, handleAgentIsReady]);

  useEffect(() => {
    if (agentInfo) {
      logger.native('ABOUT TO START CONNECTION WITH NOTIFICATION SERVICE');
      startConnection().catch(() => {
        setShowQueue(false);
        openErrorNotification(
          'topRight',
          'An error occured while establishing connection with notification service.',
          'Error'
        );
      });
    }
  }, [agentInfo]);

  const supplyAgentInfo = useCallback(
    async (agent: NotificationService) => {
      setAgentInfo(agent);
      logger.native('SUPPLYING AGENT INFO TO NOTIFICATION SERVICE');
    },
    [currentNotificationId]
  );

  return {
    currentNotificationId,
    supplyAgentInfo,
    isCallActive,
    setIsCallActive,
    callId,
    setCallId,
  };
};
