import ILLEGITIMATE from 'assets/icons/illegitimate-icon.svg';
import NORMAL from 'assets/icons/normal_icon.svg';
import SILENT from 'assets/icons/silent_icon.svg';
import { handleDispositionButtonAction } from 'modules/ipcts-call-session/components/controls/utils/handle-disposition.util';
import * as React from 'react';

import * as Styled from './call-dispositions-menu.styled';

const AnsweringMachineDispositions = () => {
  const handleDispositionAction = (disposition: string) => {
    handleDispositionButtonAction(disposition);

    switch (disposition) {
      case 'Normal':
        break;
      case 'Silent Answering Machine':
        break;
      case 'Illegitimate':
        break;
      default:
        break;
    }
  };

  return (
    <Styled.DispositionButtonsContainer>
      <Styled.AnsweringMachineModeTag>
        <Styled.AnsweringMachineModeText>
          Answering Machine Mode
        </Styled.AnsweringMachineModeText>
      </Styled.AnsweringMachineModeTag>
      <Styled.DispositionOptionButton
        id="NORMAL"
        onClick={() => handleDispositionAction('NORMAL')}
      >
        <Styled.SVGIcon src={NORMAL} />
        <Styled.ButtonText>Normal</Styled.ButtonText>
      </Styled.DispositionOptionButton>
      <Styled.DispositionOptionButton
        id="SilentAnsweringMachine"
        onClick={() => handleDispositionAction('SilentAnsweringMachine')}
      >
        <Styled.SVGIcon src={SILENT} />
        <Styled.ButtonText>Silent Answering Machine</Styled.ButtonText>
      </Styled.DispositionOptionButton>
      <Styled.DispositionOptionButton
        id="illegitimate"
        onClick={() => handleDispositionAction('Illegitimate')}
      >
        <Styled.SVGIcon src={ILLEGITIMATE} />
        <Styled.ButtonText>Illegitimate</Styled.ButtonText>
      </Styled.DispositionOptionButton>
    </Styled.DispositionButtonsContainer>
  );
};

export default AnsweringMachineDispositions;
