import { useContext, useState, useEffect } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { IpctsSession } from 'modules/ipcts-call-session/ipcts-session.component';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { UserStates } from 'state/userSessionState/userSessionStateSlice';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';

const AgentSession = () => {
  const [isHandoff, setIsHandoff] = useState(false);
  const captions = useSelector((state: RootState) => state.captions.value);
  const { updateUserState } = useUserState();
  const location = useLocation();

  const {
    isCallActive,
    setIsCallActive,
    dispositionSelected,
    setDispositionSelected,
    setSilentDispositionSelected,
  } = useContext(LandingPageContext);

  const {
    isActiveSession,
    isTokenReady,
    cardCaptionCallRef,
    handleStartListening,
    reset,
    addCustomCaption,
  } = useContext(IPCTSSessionContext);

  const dispositionCallEnd = () => {
    reset();
    setIsCallActive(false);
  };

  useEffect(() => {
    updateUserState(UserStates.IN_CALL);
  }, []);

  const handoffCallEnd = () => {
    dispositionCallEnd();
    setIsHandoff(true);
  };
  if (!isCallActive) {
    return <Navigate to="/agent-queue" state={ { from: location } }/>;
  }

  return (
    <IpctsSession
      text={captions}
      isActiveSession={isActiveSession}
      isTokenReady={isTokenReady}
      cardCaptionCallRef={cardCaptionCallRef}
      handleStartListening={handleStartListening}
      setIsCallActive={setIsCallActive}
      dispositionCallEnd={dispositionCallEnd}
      handoffCallEnd={handoffCallEnd}
      addCustomCaption={addCustomCaption}
      dispositionSelected={dispositionSelected}
      setDispositionSelected={setDispositionSelected}
      setSilentDispositionSelected={setSilentDispositionSelected}
    />
  );
};

export default AgentSession;
