/* eslint-disable react/prop-types */

import {
  HTMLAttributes,
  memo,
  SyntheticEvent,
} from 'react';
import { CaptionShard } from 'shared/hooks/axon/gateway.types';
import { ShardParagraph } from 'shared/components/ui/editor/editor/shardParagraph';

function blurContentEditable(event: any) {
  event.preventDefault();
  event.stopPropagation();
}

export const ContentEditable = memo(function ContentEditable({
  className,
  disabled,
  tagName,
  value,
  editorRef,
  contentEditable = true,
  ...rest
}: ContentEditableProps) {
  const { onFocus, ...editorProps } = rest;
  return (
    <div
      contentEditable={contentEditable}
      suppressContentEditableWarning={true}
      onBlur={blurContentEditable}
      onDragStart={e => {
        e.preventDefault();
        e.stopPropagation();
      }} 
      {...editorProps}
      className={className}
    >
      {value?.map((item, index) => (
        <ShardParagraph key={`shard-${item.shardId}`} {...item} />
      ))}
    </div>
  );
});

export type ContentEditableEvent = SyntheticEvent<any, Event> & {
  target: { name?: string; value: string };
};

export interface ContentEditableProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  name?: string;
  onChange?: (event: ContentEditableEvent) => void;
  onClick: (event: any) => void;
  tagName?: string;
  value?: CaptionShard[];
  editorRef?: any;
  isCTS?: boolean;
  contentEditable: boolean;
}
